<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>采购订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订单详情" name="first" >
          <div >
            <el-row :gutter="30">
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">订单号：</span>
                <span class="text">{{addPurchaseForm.pr_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">采购订单号：</span>
                <span class="text">{{addPurchaseForm.p_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">退货员：</span>
                <span class="text">{{addPurchaseForm.pr_prname}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">日期：</span>
                <span class="text">{{addPurchaseForm.pr_date}}</span>
              </el-col>
            </el-row>

            <el-table :data="addPurchaseForm.goodslist" border stripe  >
              <el-table-column type="index"></el-table-column>
              <el-table-column label="商品编码" prop="ga_code"></el-table-column>
              <el-table-column label="商品名称" prop="g_name"></el-table-column>
              <el-table-column label="商品规格" prop="ga_name">
                <template slot-scope="scope">
                  <div>{{scope.row.ga_name}}</div>
                  <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}-{{scope.row.gad_end_date}}</div>
                </template>
              </el-table-column>
              <el-table-column label="单位" prop="gu_name"></el-table-column>
              <el-table-column label="采购数量" prop="pi_number"></el-table-column>
              <el-table-column label="采购单价" prop="pi_price"></el-table-column>
              <el-table-column label="小计" prop="pi_total_price"></el-table-column>
            </el-table>
            <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
              <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}" :xl="{span:24,offset:0}" >-->
              <div class="pirce">
                <span class="title">退货其他金额：</span>
                <span class="text">{{addPurchaseForm.pr_other_price}}</span>
              </div>
              <div class="pirce">
                <span class="title">退货应付金额：</span>
                <span class="text" style="font-size: 18px;color: #f33333;">{{addPurchaseForm.pr_price}}</span>
              </div>
              <!--        </el-col>-->
            </el-row>
            <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
              <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

              <!--        </el-col>-->
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">备注：</span>
                <span class="text">{{addPurchaseForm.pr_remark}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">制单人：</span>
                <span class="text">{{addPurchaseForm.add_name}}</span>
              </el-col>
            </el-row>
          </div>
          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#printTest'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
        <el-tab-pane label="出库记录" name="second">
          <div>
            <div v-for="(item,index) in addPurchaseForm.warehouseout" v-bind:key="index" style="margin-bottom: 50px">
              <el-row :gutter="30">
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                  <span class="title">订单号：</span>
                  <span class="text">{{item.wo_code}}</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">仓库：</span>
                  <span class="text">{{item.w_name}}</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">经办人：</span>
                  <span class="text">{{item.wo_agent_name}}</span>
                </el-col>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">日期：</span>
                  <span class="text">{{item.wo_date}}</span>
                </el-col>
              </el-row>

              <el-table :data="item.goodslist" border stripe  >
                <el-table-column type="index"></el-table-column>
                <el-table-column label="商品编码" prop="ga_code"></el-table-column>
                <el-table-column label="商品名称" prop="g_name"></el-table-column>
                <el-table-column label="商品规格" prop="ga_name">
                  <template slot-scope="scope">
                    <div>{{scope.row.ga_name}}</div>
                    <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}-{{scope.row.gad_end_date}}</div>
                  </template>
                </el-table-column>
<!--                <el-table-column label="开始时间" prop="gad_start_date"></el-table-column>-->
<!--                <el-table-column label="结束时间" prop="gad_end_date"></el-table-column>-->
                <el-table-column label="单位" prop="gu_name"></el-table-column>
                <el-table-column label="已出库数量" prop="woi_number"></el-table-column>
              </el-table>

              <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
                <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

                <!--        </el-col>-->
              </el-row>
              <el-row>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">备注：</span>
                  <span class="text">{{item.wo_remark}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                  <span class="title">制单人：</span>
                  <span class="text">{{addPurchaseForm.add_name}}</span>
                </el-col>
              </el-row>
            </div>

          </div>
          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#printText'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
        <el-tab-pane label="退款记录" name="third">
<!--          <div v-for="(item,index) in addPurchaseForm.purchasepayment" v-bind:key="index">-->
<!--            -->
<!--          </div>-->
          <el-table :data="addPurchaseForm.purchasepayment" stripe >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="支付流水号" prop="p_serial_number"></el-table-column>
            <el-table-column label="付款日期" prop="p_payment_date"></el-table-column>
            <el-table-column label="付款金额" prop="p_payment_price">
              <template slot-scope="scope">
                <div style="color:red;font-weight: bold;">
                  {{scope.row.p_payment_price}}</div>
              </template>
            </el-table-column>
            <el-table-column label="支付方式" prop="p_payment_method">
              <template slot-scope="scope">
                <div style="padding: 2px 5px;background: #8c939d;display: inline-block;
            color:#ffffff;border-radius: 4px">
                  {{scope.row.p_payment_method}}</div>
              </template>
            </el-table-column>
            <el-table-column label="付款账户" prop="p_bank_account"></el-table-column>
          </el-table>

          <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#print'" v-if="this.$route.query.is_printing=='true'">打印</el-button>

        </el-tab-pane>
      </el-tabs>


    </el-card>
    <!--  订单详情打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <div id="printTest" style="width: 1000px;margin:0 auto;">
          <el-row :gutter="30">
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">订单号：</span>
              <span class="text">{{addPurchaseForm.pr_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">采购订单号：</span>
              <span class="text">{{addPurchaseForm.p_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">退货员：</span>
              <span class="text">{{addPurchaseForm.pr_prname}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">日期：</span>
              <span class="text">{{addPurchaseForm.pr_date}}</span>
            </el-col>
          </el-row>

          <el-table :data="addPurchaseForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name"></el-table-column>
            <el-table-column label="单位" prop="gu_name"></el-table-column>
            <el-table-column label="采购数量" prop="pi_number"></el-table-column>
            <el-table-column label="采购单价" prop="pi_price"></el-table-column>
            <el-table-column label="小计" prop="pi_total_price"></el-table-column>
          </el-table>
          <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
            <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:24,offset:0}" :lg="{span:24,offset:0}" :xl="{span:24,offset:0}" >-->
            <div class="pirce">
              <span class="title">退货其他金额：</span>
              <span class="text">{{addPurchaseForm.pr_other_price}}</span>
            </div>
            <div class="pirce">
              <span class="title">退货应付金额：</span>
              <span class="text" style="font-size: 18px;color: #f33333;">{{addPurchaseForm.pr_price}}</span>
            </div>
            <!--        </el-col>-->
          </el-row>
          <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
            <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

            <!--        </el-col>-->
          </el-row>
          <el-row>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">备注：</span>
              <span class="text">{{addPurchaseForm.pr_remark}}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">制单人：</span>
              <span class="text">{{addPurchaseForm.add_name}}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!--  入库记录打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <div id="printText" style="width: 1000px;margin:0 auto;">
          <div v-for="(item,index) in addPurchaseForm.warehouseenter" v-bind:key="index" style="margin-bottom: 50px">
            <el-row :gutter="30">
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
                <span class="title">订单号：</span>
                <span class="text">{{item.wo_code}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">仓库：</span>
                <span class="text">{{item.w_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">经办人：</span>
                <span class="text">{{item.wo_agent_name}}</span>
              </el-col>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">日期：</span>
                <span class="text">{{item.wo_date}}</span>
              </el-col>
            </el-row>

            <el-table :data="item.goodslist" border stripe  >
              <el-table-column type="index"></el-table-column>
              <el-table-column label="商品编码" prop="ga_code"></el-table-column>
              <el-table-column label="商品名称" prop="g_name"></el-table-column>
              <el-table-column label="商品规格" prop="ga_name">
                <template slot-scope="scope">
                  <div>{{scope.row.ga_name}}</div>
                  <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}-{{scope.row.gad_end_date}}</div>
                </template>
              </el-table-column>
              <el-table-column label="单位" prop="gu_name"></el-table-column>
              <el-table-column label="已入库数量" prop="woi_number"></el-table-column>
            </el-table>

            <el-row style="display: flex; flex-direction: column;align-items: flex-end; justify-content: flex-end;">
              <!--        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >-->

              <!--        </el-col>-->
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">备注：</span>
                <span class="text">{{addPurchaseForm.wo_remark}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
                <span class="title">制单人：</span>
                <span class="text">{{addPurchaseForm.add_name}}</span>
              </el-col>
            </el-row>
          </div>

        </div>
      </div>
    </div>
    <!--  付款记录打印  -->
    <div>
      <div style="opacity: 0;height: 0;">
        <el-table :data="addPurchaseForm.purchasepayment" stripe style="width: 1000px;margin:0 auto;"  id="print">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="支付流水号" prop="p_serial_number"></el-table-column>
          <el-table-column label="付款日期" prop="p_payment_date"></el-table-column>
          <el-table-column label="付款金额" prop="p_payment_price"></el-table-column>
          <el-table-column label="支付方式" prop="p_payment_method"></el-table-column>
          <el-table-column label="付款账户" prop="p_bank_account"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      activeName: 'first',
      addPurchaseForm:{}
    }
  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`purchasereturn/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      // this.querInfo.s_id=res.data.s_id
      // this.getGoodsList(this.querInfo)
      // this.adddisabled=false

    },
    //tab切换
    handleClick(tab, event) {
      console.log(tab, event);
    }
  },
  created(){
    this.getpurchaseList()
  }
}
</script>
<style>
.el-col{
  display: flex;
}
.pirce{
  width: 200px;
}
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
@media print {
  html,
  body {
    height: inherit;
  }
}
</style>
